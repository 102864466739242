<button
  (click)="handleClick($event)"
  [disabled]="disabled || isLoading || isHTTPLoading"
  [ngClass]="class"
  [type]="type"
  [attr.data-id]="buttonDataId"
>
  <ng-container *ngIf="!isLoading && !isHTTPLoading; else loadingTemplate"> {{ buttonText }} </ng-container>

  <ng-template #loadingTemplate>
    <mat-spinner diameter="14" strokeWidth="2"></mat-spinner>
    {{ loadingText }}
  </ng-template>
</button>
