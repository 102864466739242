import { OnboardingStepLabels } from '@app/onboarding/types/enums/onboarding-step.enum';

export const ONBOARDING_STEPS = [
  {
    label: 'context',
  },
  {
    label: 'company',
  },
  {
    label: 'user',
  },
];

export const SELF_ONBOARDING_SUBSTEPS = [
  {
    label: OnboardingStepLabels.CONTEXT_GOALS,
    title: "Let's Achieve Your Startup Goals",
    description: 'Share your role & primary objective so we can tailor our services to your needs.',
  },
  {
    label: OnboardingStepLabels.CONTEXT_COMPANY,
    title: 'Tell Us About Your Company',
    description: 'Provide Key Information to Shape Your Experience.',
  },
  {
    label: OnboardingStepLabels.COMPANY_PROFILE,
    title: 'Craft Your Company Profile',
    description: "Set up your company's profile with essential details.",
  },
  {
    label: OnboardingStepLabels.USER_PROFILE,
    title: 'Create Your User Profile',
    description: 'Unlock a Personalised Journey within Our Platform.',
  },
  {
    label: OnboardingStepLabels.USER_SECURITY,
    title: 'Set Up Your Secure Password',
    description: 'Protect Your Account with a Strong and Unique Password',
  },
  {
    label: OnboardingStepLabels.READY_TO_START,
    title: 'Are you ready to start?',
    description:
      '<p>You’ve successfully registered to the WE.VESTR platform!</p><p>Please continue to the platform and see what the system has to offer.</p>',
  },
];

export const GUIDED_ONBOARDING_SUBSTEPS = [
  {
    label: OnboardingStepLabels.CONTEXT_GOALS,
    title: "Let's Achieve Your Startup Goals",
    description: 'Share your role & primary objective so we can tailor our services to your needs.',
  },
  {
    label: OnboardingStepLabels.CONTEXT_COMPANY,
    title: 'Tell Us About Your Company',
    description: 'Provide Key Information to Shape Your Experience.',
  },
  {
    label: OnboardingStepLabels.COMPANY_PROFILE,
    title: 'Craft Your Company Profile',
    description: "Set up your company's profile with essential details.",
  },
  {
    label: OnboardingStepLabels.USER_PROFILE,
    title: 'You have been invited!',
    description: `You have been invited to use the WE.VESTR platform.`,
  },
  {
    label: OnboardingStepLabels.USER_SECURITY,
    title: 'Set Up Your Secure Password',
    description: 'Protect Your Account with a Strong and Unique Password',
  },
  {
    label: OnboardingStepLabels.READY_TO_START,
    title: 'Are you ready to start?',
    description:
      '<p>You’ve successfully registered to the WE.VESTR platform!</p><p>Please continue to the platform and see what the system has to offer.</p>',
  },
];

export const FIRST_STEP_INDEX = 0;
export const FIRST_SUBSTEP_INDEX = 0;

export const PLATFORM_TITLE = 'Equity Management. Simplified.';

export const BUTTONS = {
  CONTINUE: 'Continue',
  LOADING: 'Please Wait',
  BACK: 'Back',
};

export const OPTIONAL = '(Optional)';

export const CONTACT_US_TEXT = 'For further assistance, reach us at';
