import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';

import { ButtonService } from './button.service';
import { HttpLoadingService } from '@app/shared/services/http-loading.service';

@Component({
  selector: 'wevestr-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @HostBinding('attr.class') classAttr = 'full-width flex';

  @Input() buttonText = 'Continue';
  @Input() loadingText = 'Please Wait';
  @Input() disabled = false;
  @Input() type = 'submit';
  @Input() class = 'button primary-button app-body full-width';
  @Input() buttonDataId?: string;

  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();

  public isLoading = false;
  public isHTTPLoading = false;

  constructor(private buttonService: ButtonService, private httpLoadingService: HttpLoadingService) {
    this.buttonService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });

    this.httpLoadingService.isLoading$.subscribe((loading) => {
      this.isHTTPLoading = loading;
    });
  }

  public handleClick(event: Event): void {
    this.onClick.emit(event);
  }
}
